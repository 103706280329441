const admin = [
  {
    text: '数据统计',
    icon: 'iconfont icon-shouye',
    is_show: true,
    child: [
      {
        text: '数据概括',
        path: '/home',
        show: true
      }
    ]
  },

  {
    text: '资料管理',
    icon: 'iconfont icon-shangjia',
    is_show: true,
    child: [
      {
        text: '数据管理',
        path: '/product',
        show: true
      },
      {
        text: '分类管理',
        path: '/lawyer',
        show: true
      },
      // {
      //   text: '岗位管理',
      //   path: '/problem',
      //   show: true
      // },
      {
        text: '数据表单',
        path: '/index',
        show: true
      },
      {
        text: '审核数据',
        path: '/examine',
        show: true
      },
      {
        text: '公告管理',
        path: '/announcement',
        show: true
      },
       {
        text: '用户管理',
        path: '/user',
        show: true
      },
      //  {
      //   text: '用户挪车数据',
      //   path: '/user-car',
      //   show: true
      // }
      // {
      //   text: '轮播图管理',
      //   path: '/banner',
      //   show: true
      // },
    ]
  },
  {
    text: '挪车管理',
    icon: 'iconfont icon-chuzu',
    is_show: true,
    child: [
      {
        text: '用户挪车数据',
        path: '/car-phone',
        show: true
      }
      // {
      //   text: '挪车数据',
      //   path: '/car-data',
      //   show: true
      // }
    ]
  },
  // {
  //   text: '系统设置',
  //   icon: 'iconfont icon-order',
  //   is_show: true,
  //   child: [
  //     {
  //       text: '网站信息',
  //       path: '/info',
  //       show: true
  //     }
  //   ]
  // },
]
module.exports = {
  admin: admin,
}