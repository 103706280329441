<template>
  <div class="login-container">
    <div class="login">
      <div class="box">
        <el-form ref="form" class="login-form" label-position="left">
          <div class="title">通讯录后台管理系统</div>
          <el-form-item style="margin-top: 40px" prop="userName">
            <span class="t_span">账号</span>
            <el-input v-model.number="username" placeholder="输入账号"></el-input>
          </el-form-item>
          <el-form-item style="margin-top: -15px" prop="password">
            <span class="t_span">密码</span>
            <el-input type="password" v-model="password" placeholder="请输入您的密码"></el-input>
          </el-form-item>

          <el-button class="login-btn" type="primary" @click="login" @keyup.enter.native="enterLogin()">登录</el-button>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: "",
      password: "",
      code: "",
      code_img: "",
      uuid: ""
    };
  },
  created() {
    this.enterLogin()
  },
  methods: {
   async login() {
      if(!this.username) { return this.$message.error("请输入登录账号")}
      if(!this.password) { return this.$message.error("请输入登录密码")}
      if(this.username != 'admin') {
        return this.$message.error("用户名不正确")
      }
       if(this.password != 'admin123') {
        return this.$message.error("密码不正确")
      }
      this.$store.commit('login',this.username)
      this.$router.push("/");
    },
    enterLogin(){
      document.onkeydown =(e) => {
        e = window.event || e;
        if(this.$route.path=='/login'&&(e.code=='Enter'||e.code=='enter')){
          //调用登录事件方法
          this.login();
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-input__inner {
  height: 50px;
  font-size: 15px;
}
/deep/ .el-form-item {
  margin-bottom: 10px !important;
}
/deep/ .el-form-item__content {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

/deep/ .el-input.is-active .el-input__inner,
.el-input__inner:focus {
  border-color: #2e5afb !important;
}

.login-container {
  width: 100%;
  height: 100vh;
  min-width: 1200px;
  background-size: cover;
  .login {
    width: 100%;
    height: 100vh;
    background: red;
    background: url("../assets/background.jpg") center center no-repeat;
    background-size: cover;
    position: relative;
    .box {
      height: auto;
      display: flex;
      position: absolute;
      right: 10%;
      top: 45%;
      transform: translateX(-10%);
      transform: translateY(-45%);
      .title {
        font-size: 30px;
        font-weight: 500;
        color: rgba(14, 18, 26, 1);
        font-weight: bold;
      }

      .t_span {
        font-size: 16px;
      }

      .check {
        display: flex;

        .code {
          width: 150px;
          height: 50px;
          margin-left: 20px;
          img {
            width: 100%;
            height: 100%;
            cursor: pointer;
          }
        }
      }

      .login-btn {
        display: inherit;
        width: 400px;
        height: 50px;
        margin-top: 30px;
        border: 0;
        font-size: 14px;
        background: #2e5afb !important;
        &:hover {
          opacity: 0.9;
        }
      }
    }
  }
}
</style>


