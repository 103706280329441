import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'

// import store from '../store/index'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: '/home',
        component: resolve => require(['../views/home/index.vue'], resolve),
        meta: { requireAuth: true },
      }, {
        path: '/car-phone',
        name: '/car-phone',
        component: resolve => require(['../views/car/index.vue'], resolve),
        meta: { requireAuth: true },
      },{
      path: '/product',
      name: '/product',
      component: resolve => require(['../views/product/index.vue'], resolve),
      meta: { requireAuth: true },
    },{
      path: '/problem',
      name: '/problem',
      component: resolve => require(['../views/problem/index.vue'], resolve),
      meta: { requireAuth: true },
    },{
      path: '/lawyer',
      name: '/lawyer',
      component: resolve => require(['../views/lawyer/index.vue'], resolve),
      meta: { requireAuth: true },
    },{
      path: '/data',
      name: '/data',
      component: resolve => require(['../views/data/index.vue'], resolve),
      meta: { requireAuth: true },
    },{
      path: '/index',
      name: '/index',
      component: resolve => require(['../views/index/index.vue'], resolve),
      meta: { requireAuth: true },
    },{
      path: '/banner',
      name: '/banner',
      component: resolve => require(['../views/banner/index.vue'], resolve),
      meta: { requireAuth: true },
    },{
      path: '/examine',
      name: '/examine',
      component: resolve => require(['../views/index/examine.vue'], resolve),
      meta: { requireAuth: true },
    },{
      path: '/announcement',
      name: '/announcement',
      component: resolve => require(['../views/announcement/index.vue'], resolve),
      meta: { requireAuth: true },
    },{
      path: '/user',
      name: '/user',
      component: resolve => require(['../views/home/user.vue'], resolve),
      meta: { requireAuth: true },
    },{
      path: '/user-car',
      name: '/user-car',
      component: resolve => require(['../views/home/car.vue'], resolve),
      meta: { requireAuth: true },
    }]
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login,
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
