import Vue from 'vue'
import Vuex from 'vuex'
// upInformation

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    access_token: sessionStorage.getItem('access_token') ? sessionStorage.getItem('access_token') : '',
    userData: sessionStorage.getItem('userData') ? JSON.parse(sessionStorage.getItem('userData')) : {}
  },
  mutations: {
    login(state,data) {
      state.token = data
      sessionStorage.setItem("username", data); 
    },
    logout() {
      sessionStorage.removeItem('access_token')
    },
    user(state,data) {
      state.userData = data
      sessionStorage.setItem("userData", JSON.stringify(data)); 
    },
  },
  actions: {
  },
  modules: {
  }
})
