<template>
  <div id="app">
    <router-view v-if="routerAlive"></router-view>
  </div>
</template>

<script>
// import { getStatus } from "./api/machine";
export default {
  provide() {
    return {
      reload: this.reload
    };
  },
  data() {
    return {
      routerAlive: true
    };
  },
  methods: {
    reload() {
      this.routerAlive = false;
      this.$nextTick(function() {
        this.routerAlive = true;
      });
    }
  },
  created() {
    // getStatus('pdpvsadi',{
    //   TaskType: 'GetAllGroupContact',
    //   DBSync: true
    // }).then(res1 => {
    //   console.log(res1)
    // })
    // getControl(JSON.parse(JSON.stringify({ sandbox: "md5" }))).then(res => {
    //   console.log(res)
    //   getStatus('md5').then(res1 => {
    //     console.log(res1)
    //   })
    // });
  }
};
</script>

<style lang="less">
@font-face {
  font-family: "SYH";
  src: url("./font/font.otf");
  font-weight: normal;
  font-style: normal;
}

h1 {
  font-size: 25px;
}

.tab-title {
  height: 50px;
  font-weight: 500;
  font-size: 15px;
}

.nav {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  h1 {
    font-size: 20px;
  }
  .el-tag {
    margin-left: 10px;
    background: #f57a3d24;
    color: #f57a3d;
    font-weight: 500;
    margin-right: 20px;
  }
  p {
    color: #f57a3d;
    font-weight: 600;
    font-size: 16px;
    margin-left: 20px;
    cursor: pointer;
  }
}
// .el-date-table td.today span {
//   color: #f57a3d !important;
// }



// .el-table .descending .sort-caret.descending {
//     border-bottom-color: #F57A3D !important;
// }

// .el-table .ascending .sort-caret.ascending {
//   border-bottom-color: #F57A3D !important;
// }

* {
  padding: 0;
  margin: 0;
  font-family: "SYH";
}
body {
  background: #f7f7f7;
}
#app {
  background: #f7f7f7;
}
.pagination {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.el-upload-list__item.is-success .el-upload-list__item-status-label {
  display: none;
}



.el-upload--picture-card i {
  font-size: 40px !important;
}

.el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
  content: "*" !important;
  color: red !important;
  margin-right: 4px;
  font-weight: 800;
}

.el-dialog__footer {
  display: flex;
  justify-content: flex-end;
}

.result {
  margin-top: 20px;
  display: flex;
  align-items: center;
  span {
    width: 5px;
    height: 25px;
    border-radius: 20px;
    background: #ed7d30;
    display: block;
  }
  p {
    font-size: 18px;
    font-weight: 800;
    margin-left: 10px;
  }
}

// /deep/ .el-button--primary {
//     background-color: #F57A3D;
//     border-color: #F57A3D;
// }

// .el-pagination.is-background .el-pager li:not(.disabled).active {
//   background: #F57A3D !important;
// }

.btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-btn {
  width: 24px;
  height: 24px;
  border-radius: 5px;
  background: #ebeef4;
  color: #60618a;
  cursor: pointer;
  transition: all 0.3s;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  .el-tooltip {
    margin-left: 5px;
    margin-right: 5px;
  }
}

// .el-button:focus, .el-button:hover {
//   color: #f57a3d !important;
//   background: #fef2ec !important;
//   border-color: #fbcab1 !important;
// }
</style>